exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-draft-infoevent-index-tsx": () => import("./../../../src/templates/draft/infoevent/index.tsx" /* webpackChunkName: "component---src-templates-draft-infoevent-index-tsx" */),
  "component---src-templates-draft-private-index-tsx": () => import("./../../../src/templates/draft/private/index.tsx" /* webpackChunkName: "component---src-templates-draft-private-index-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/news/detail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-news-paging-tsx": () => import("./../../../src/templates/news/paging.tsx" /* webpackChunkName: "component---src-templates-news-paging-tsx" */),
  "component---src-templates-private-detail-tsx": () => import("./../../../src/templates/private/detail.tsx" /* webpackChunkName: "component---src-templates-private-detail-tsx" */)
}

